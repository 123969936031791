<template>
  <div>
    <vue-ueditor-wrap
      v-model="content"
      ref="refEditorObserver"
      :config="myConfig"
      @beforeInit="addVueDialogButton"
    />

    <!-- 图片选择器 -->
    <image-select
      v-model="selectImageShow"
      :type="selectType"
      @set-image="getImage"
    />
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onMounted, computed, watch } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import VueUeditorWrap from 'vue-ueditor-wrap'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required, integer } from '@validations'
import router from '@/router'
import ImageSelect from '@/comps/ImageSelect'

export default {
  directives: {
    Ripple
  },
  components: {
    formValidation,
    ValidationProvider,
    ValidationObserver,

    VueUeditorWrap,
    ImageSelect,
  },

  setup(props, { root, emit }) {
    /* 必要组件加载区域 */

    // 权限组件 弹出确认组件
    const { $can } = root
    // 消息提醒组件
    const toast = useToast()

    // editor 对象
    const refEditorObserver = ref(null)

    /* 组件页面数据准备区域，包括表单，下拉选择项，数据整理操作等 */
    
    // 图片选择器控制
    const selectImageShow = ref(false)
    // 图片选择类型， 单图,多图
    const selectType = ref(1)
    // 处理图片插入
    const getImage = (value, type, param) => {
      let str = ''
      value.forEach(el => { str += '<img src="' + el.path + '">' })
      refEditorObserver.value.editor.focus()
      refEditorObserver.value.editor.execCommand('inserthtml',str)
    }

    const content = ref('')
    // 自定义图片选择，视频上传 toolbar
    const addVueDialogButton = editorId => {
      window.UE.registerUI(
        'image-button',
        (editor, uiName) => {
          const btn = new window.UE.ui.Button({
            name: uiName,
            title: '上传图片',
            cssRules: 'background-image: url(../../../assets/images/icons.png);background-position: -726px -77px;',
            onclick: () => {
              console.log(2222, refEditorObserver.value)
              selectImageShow.value = true
            },
          })

          return btn
        },
        37,
        editorId
      )

      window.UE.registerUI(
        'video-button',
        (editor, uiName) => {
          const btn = new window.UE.ui.Button({
            name: uiName,
            title: '上传视频',
            cssRules: 'background-image: url(../../../assets/images/icons.png);background-position: -320px -20px;',
            onclick() {
              console.log(3333)
              selectImageShow.value = true
            },
          })

          return btn
        },
        38,
        editorId
      )
    }

    //编辑器配置
    const myConfig = ref({
        autoHeightEnabled: true, // 编辑器不自动被内容撑高
        initialFrameHeight: 320, // 初始容器高度
        // initialFrameWidth: 766, // 初始容器宽度
        initialFrameWidth: '100%', // 初始容器宽度
        UEDITOR_HOME_URL: '/UEditor/', // public 目录下目录
        serverUrl: '', // 服务器端地址
        zIndex: 900
    })

    /* 接口调用区域 */

    /* 组件内部逻辑处理，生命周期问题处理区域 */

    // 处理基本下拉信息加载，权限判断等
    onMounted(() => {})

    /* 分割线 */

    return {
      required,
      integer,

      refEditorObserver,
      myConfig,
      content,
      addVueDialogButton,

      getImage,
      selectType,
      selectImageShow,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>